$c_black: #1f2123;
$c_white: #f5f5f5;
$c_red: #821b23;
$c_focus: #006ed8;
$c_card: rgba(128,128,128, 0.25);

$s_base-font--wide: 18px;
$s_base-font--narrow: 14px;
$s_padding-small: 0.75em;
$s_padding: var(--paddingSize, 1em);
$s_padding-medium: 1.5em;
$s_padding-large: 2.5em;
$s_breakpoint--narrow: 56em;
$s_border-radius: $s_padding;
$s_border-radius-small: $s_padding-small;
$s_border-radius-tiny: 0.25em;

$a_transition-time: 60ms ease-out;

$f_primary: ui-sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;

