@import 'variables';
.icon {
  display: inline-block;
  vertical-align: middle;
  width: 2em;
  height: 2em;
  &.small {
    width: 1.25em;
    height: 1.25em;
  }
  &.large {
    width: 3em;
    height: 3em;
  }
  &.extra-large {
    width: 4em;
    height: 4em;
  }
  svg {
    max-width: 100%;
    max-height: 100%;
    fill: currentColor;
  }
  &.spin {
    animation: spin 1.25s infinite ease-in-out;
  }
  &.margin-top {
    margin-top: 2em;
  }
  &.margin-right {
    margin-right: 2em;
  }
  &.margin-bottom {
    margin-bottom: 2em;
  }
  &.margin-left {
    margin-left: 2em;
  }
  h1 &,
  h2 &,
  h3 &,
  h4 &,
  h5 & {
    margin-bottom: 1em;
    margin-left: -0.2em;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(720deg);
  }
}
